@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');

:root {
  --dark-bg-color: hsl(207, 26%, 17%);
  --article-color: hsl(209, 23%, 22%);
  --text-color: hsl(0, 0%, 100%);
  --light-mode-text: hsl(200, 15%, 8%);
  --light-mode-input: hsl(0, 0%, 52%);
  --light-mode-background: hsl(0, 0%, 98%);
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: 'Nunito Sans', sans-serif;
  padding: 20px;
  background-color: #202d36;
  color: #fff;
}
/* header */
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2d3743;
  padding: 10px 20px;
}
/* ============Filter ======================== */
.filter{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 20px;
}

.filter .form-control{
  width: 100%;
}
.filter .form-control input{
  background-color: #2d3743;
  border: none;
  outline: none;
  padding: 12px;
  border-radius: 5px;
  color: #fff;
  font-family: inherit;
  letter-spacing: 0.5px;
  width: 100%;
  margin: 0 0 30px;  

}
.filter .form-control input::placeholder{
  color: #fff;
}


.region-filter .select{
  background-color: #2d3743;
  color: #fff;
  font-family: inherit;
  padding: 8px;
  width: 200px;
  border: none;
}
/* ============Grid body area ================= */

img{
  max-width: 100%;
}
ul{
  list-style-type: none;
}
.buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn{
  background-color: var(--dark-bg-color);
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  border: 2px solid var(--dark-bg-color);
  border-radius: 4px;
  transition: background-color 0.2s;
}
.btn:hover{
  background-color: #fff;
  color: var(--dark-bg-color);
}
.btn-light{
  background-color: var(--article-color);
  border: 2px solid var(--article-color);
}

.grid{
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  /* box-shadow: 0 10px 10px rgba(red, green, blue, alpha); */
}
.grid article img{
  border-radius: 5px 5px 0 0;
  height: 200px;
  width: 100%;
}
.grid .details{
  background-color: #2d3743;
  padding: 20px;
  margin-top: -20px;
  border-radius: 0 0 5px 5px;
}
.grid .details h3{
  padding-bottom: 5px;
}
.grid .details h4{
  padding-bottom: 5px;
}
grid .details h4 span{
  font-weight: 300;
}


/* ==============For Responsive =========== */

@media(min-width:640px){
  .grid{
    grid-template-columns: repeat(2, 1fr);
  }

}
@media(min-width:768px){
  .filter{
    flex-direction: row;
    justify-content: space-between;
  }
  .filter .form-control{
    width: auto;
  }
  .filter .form-control input{
    width: auto;
    margin: 0;
  }
  .filter .region-filter{
    width: auto;
  }
  .grid{
    grid-template-columns: repeat(3, 1fr);
  }

}
@media(min-width:992px){
  .header,
  .filter,
  .grid{
    padding: 10px 6px;
  }
  .grid{
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }

}